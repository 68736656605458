import styled from "styled-components";

export const Container = styled.section`
  position: fixed;
  display: flex;
  flex-direction: row;

  width: 100%;
  padding: 15px;

  left: 0;
  right: 0;
  bottom: 0;

  background-color: #fff;
  z-index: 1;
`;
