import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

interface UrlProps<T> {
 query: Record<any,any>,
 path: T,
}

function useQuery<pathType = unknown>(): UrlProps<pathType> {
    const location = useLocation();
    const urlParams: UrlProps<pathType> = {
      path: location.pathname as unknown as pathType,
      query: queryString.parse(location.search ? location.search : location.hash),
    }
    return urlParams
  }
export default useQuery;