export const parseStripeErrorMessage = (error: any) => {
  let message = "Ocorreu um erro ao processar o pagamento";
  switch (error.code) {
    case "incomplete_number":
      message = "O número do cartão está incompleto";
      break;
    case "invalid_number":
      message = "O número do cartão está inválido";
      break;
    case "incomplete_expiry":
      message = "A data de expiração está incompleta";
      break;
    case "incomplete_cvc":
      message = "O código de segurança está incompleto";
      break;
    case "invalid_expiry_year":
      message = "O ano de expiração está inválido";
      break;
    case "invalid_expiry_year_past":
      message = "O ano de expiração está no passado";
      break;
    case "invalid_expiry_month_past":
      message = "A data de expiração está no passado";
      break;
    case "card_declined":
      message = "O seu cartão foi rejeitado";
      break;
    default:
      message = error?.message || message;
  }
  return message;
};
