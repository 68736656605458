import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: 0 15px;
`;

export const Description = styled.h3<{ active?: boolean }>`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  flex: 1;
  padding-left: 15px;
  letter-spacing: 0.15px;
  text-decoration-line: ${({ active }) => (active ? "none" : "line-through")};

  color: ${({ active }) => (active ? "#1f1f1f" : "#D1D1D1")};

  ${({ active }) =>
    active &&
    css`
      & span {
        font-weight: bold;
        color: #e0552f;
      }
    `}
`;

export const PlatformWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PlatformDivisor = styled.div`
  height: 20px;
  width: 2px;
  margin-left: 5px;
  margin-right: 8px;
  background-color: #f1f1f1;
`;
