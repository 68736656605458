import { CheckmarkStarburst } from "@styled-icons/fluentui-system-filled";

interface CheckIconProps {
  size?: number;
  active?: boolean;
}

const CheckIcon = ({ active, size, ...iconProps }: CheckIconProps) => (
  <CheckmarkStarburst
    color={active ? "#E4531A" : "#D1D1D1"}
    size={22}
    {...iconProps}
  />
);

export default CheckIcon;
