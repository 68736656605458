import React from "react";
import { Feature as FeatureType } from "../../../../@types/types";
import { CheckIcon, DesktopIcon, MobileIcon } from "../../../../assets/Icons";
import parseStringToHTML from "html-react-parser";

import {
  Container,
  Description,
  PlatformWrapper,
  PlatformDivisor,
} from "./styles";

const Feature = ({ active, platform, description }: FeatureType) => {
  return (
    <Container>
      <CheckIcon active={active} />
      <Description active={active}>
        {parseStringToHTML(description)}
      </Description>
      {active && (
        <PlatformWrapper>
          <MobileIcon active={platform === "mobile" || platform === "both"} />
          <PlatformDivisor />
          <DesktopIcon active={platform === "desktop" || platform === "both"} />
        </PlatformWrapper>
      )}
    </Container>
  );
};

export default Feature;
