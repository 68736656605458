import React from "react";
import Button from "../../components/Button";
import logo from "../../assets/logo.svg";
import useRedirectScreen from "../../hooks/useRedirectScreen";

import { Container, Subtitle } from "./styles";

const RedirectScreen: React.FC = () => {
  const route = useRedirectScreen();

  return (
    <Container>
      <img src={logo} alt="logo" />
      <Subtitle>{route.title}</Subtitle>
      <Button
        title={route.buttonTitle}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = route.redirectTo;
        }}
      />
    </Container>
  );
};

export default RedirectScreen;
