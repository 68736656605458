import React from "react";
import Button from "../Button";

import { Container } from "./styles";

interface BottomNavigationProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  buttonLabels?: {
    confirm: string;
    cancel: string;
  };
}

const BottomNavigation = ({
  buttonLabels,
  onCancel,
  onConfirm,
}: BottomNavigationProps) => {
  return (
    <Container
      style={{
        justifyContent: onConfirm && !onCancel ? "flex-end" : "space-between",
      }}
    >
      {onCancel && (
        <Button onClick={onCancel} title={buttonLabels?.cancel || "Voltar"} />
      )}
      {onConfirm && (
        <Button
          onClick={onConfirm}
          title={buttonLabels?.cancel || "Continuar"}
        />
      )}
    </Container>
  );
};

export default BottomNavigation;
