import styled from 'styled-components';

export const Container = styled.div`
    text-align: center;
    height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
`;

export const Subtitle = styled.p`
    margin: 15px 0px;
`