import React from "react";
import { DeviceDesktop } from "@styled-icons/octicons";

interface DesktopIconProps {
  active?: boolean;
  size?: number;
}

const DesktopIcon = ({ active, size, ...iconProps }: DesktopIconProps) => {
  return (
    <DeviceDesktop
      color={active ? "#E4531A" : "#F1F1F1"}
      size={size || 18}
      {...iconProps}
    />
  );
};

export default DesktopIcon;
