import styled from "styled-components";

export const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #f8f8f8;
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.5%, #ffffff 5%);
`;

export const Title = styled.h1`
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100%;
  font-family: Poppins;
`;

export const PlansCaroussel = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 10px 0px;
  margin-top: 15px;
  justify-content: start;
  min-height: 180px;

  scroll-behavior: smooth;

  // Align cards on desktop
  @media (min-width: 768px) {
    justify-content: center;
  }

  /* Hide scrollbar for Chrome */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;
