import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Checkout from "../screens/Checkout";
import Plans from "../screens/Plans";
import RedirectScreen from "../screens/RedirectScreen";

const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={RedirectScreen} />
        <Route path="/login" component={RedirectScreen} />
        <Route path="/user-subscribed" component={RedirectScreen} />
        <Route path="/assinatura/planos" component={Plans} />
        <Route path="/assinatura/checkout" component={Checkout} />
        <Route path="*" component={RedirectScreen} />
      </Switch>
    </Router>
  );
};

export default Routes;
