import { useEffect, useState } from "react";
import { Plan } from "../../@types/types";
import Loading from "../../components/Loading";
import { Plans } from "../../data";
import useQuery from "../../hooks/useQuery";
import {
  Container,
  Title,
  CardContainer,
  CardDescription,
  CouponWrapper,
  PaymentDetails,
  CouponInput,
  Wrapper,
} from "./styles";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckout from "./StripeCheckout";
import Button from "../../components/Button";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const stripePromise = loadStripe("pk_live_Q2veO6rCESOo3U5t9ccoBLs0");

const Checkout = () => {
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [selectedRecurrence, setSelectedRecurrence] = useState<
    "monthly" | "yearly"
  >("monthly");
  const [loading, setLoading] = useState(true);
  const [couponId, setCouponId] = useState<string>("");
  const [couponDiscount, setCouponDiscount] = useState<number>(0);
  const [planPrice, setPlanPrice] = useState<number>(0);

  const history = useHistory();

  const { query } = useQuery();

  const handleCouponVerification = async () => {
    setLoading(true);
    api
      .get(`/coupons/${couponId}`)
      .then((response) => {
        if (response?.data?.percent_off_precise) {
          const planId =
            selectedPlan?.recurrence[selectedRecurrence].stripe_plan_id;
          if (response?.data?.applies_to?.products?.includes(planId)) {
            const newPlanPrice =
              planPrice -
              planPrice * (response?.data?.percent_off_precise / 100);
            setPlanPrice(newPlanPrice);
            setCouponDiscount(response?.data?.percent_off_precise);
            toast.success("Cupom aplicado");
          } else {
            toast.error("Plano não compatível com o cupom");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || "Cupom Invalido");
        setPlanPrice(
          selectedPlan?.recurrence?.[selectedRecurrence]?.rawPrice || 0
        );
        setCouponDiscount(0);
        setCouponId("");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      api.defaults.headers.common["X-User-Token"] &&
      api.defaults.headers.common["X-User-Email"]
    ) {
      if (query?.plan && query?.recurrence) {
        const tmpSelectedPlan = Plans.find((it) => it.label === query?.plan);
        if (tmpSelectedPlan) {
          const queryRecurrence =
            query?.recurrence === "monthly" ? "monthly" : "yearly";

          setSelectedPlan(tmpSelectedPlan);
          setPlanPrice(
            tmpSelectedPlan?.recurrence?.[queryRecurrence]?.rawPrice
          );
          setSelectedRecurrence(queryRecurrence);
          setLoading(false);
          return;
        }
      }
    }
    return history.push("/");
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Wrapper>
        <Title>PAGAMENTO</Title>

        <CardContainer>
          <div>
            <CardDescription isTitle>Detalhes</CardDescription>
          </div>

          <PaymentDetails>
            <div>
              <CardDescription>Plano:</CardDescription>
              <CardDescription>{selectedPlan?.label}</CardDescription>
            </div>
            {!!couponDiscount && (
              <>
                <div>
                  <CardDescription>Valor original:</CardDescription>
                  <CardDescription>
                    {selectedPlan?.recurrence?.[selectedRecurrence]
                      ?.userPrice || "R$ 0,00"}
                  </CardDescription>
                </div>
                <div>
                  <CardDescription>Desconto:</CardDescription>
                  <CardDescription>{`${couponDiscount}%`}</CardDescription>
                </div>
              </>
            )}
          </PaymentDetails>

          <div>
            <CardDescription isTitle style={{ color: "#E4531A" }}>
              Total a Pagar:
            </CardDescription>
            <CardDescription
              style={{ fontWeight: 500, fontFamily: "Ubuntu", lineHeight: 1.5 }}
            >
              {planPrice?.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
              <span style={{ fontSize: 18, color: "#E4531A" }}>
                {selectedRecurrence === "monthly" ? "/mês" : "/ano"}
              </span>
            </CardDescription>
          </div>
        </CardContainer>

        <h3 style={{ marginTop: 15 }}>Cupom de Desconto</h3>
        <CouponWrapper>
          <CouponInput
            value={couponId}
            onChange={(e) => {
              setCouponId(e.target.value);
            }}
            placeholder="CUPOM"
          />
          <Button
            title="Aplicar"
            style={{ flex: 2 }}
            onClick={handleCouponVerification}
          />
        </CouponWrapper>

        <Elements stripe={stripePromise}>
          <StripeCheckout
            setLoading={setLoading}
            couponId={couponId}
            planId={
              selectedPlan?.recurrence?.[selectedRecurrence]?.menu_plan_id
            }
          />
        </Elements>
      </Wrapper>
    </Container>
  );
};

export default Checkout;
