import React from "react";
import { MobileAlt } from "@styled-icons/boxicons-regular";

interface MobileIconProps {
  active?: boolean;
  size?: number;
}

const MobileIcon = ({ active, size, ...iconProps }: MobileIconProps) => {
  return (
    <MobileAlt
      color={active ? "#E4531A" : "#F1F1F1"}
      size={size || 20}
      {...iconProps}
    />
  );
};
export default MobileIcon;
