import { Plan } from "../@types/types";

export const Plans: Plan[] = [
  {
    label: "Básico",
    recurrence: {
      monthly: {
        stripe_plan_id: "prod_FV9gXHgWShRh3S",
        menu_plan_id: 15,
        rawPrice: 29.9,
        userPrice: "R$29,90",
      },
      yearly: {
        stripe_plan_id: "prod_JqbZzDeoaWoag4",
        menu_plan_id: 38,
        rawPrice: 249.9,
        userPrice: "R$ 249,90",
      },
    },
    features: [
      {
        description: "Matérias-primas <span>Ilimitadas</span>",
        active: true,
        platform: "both",
      },
      {
        description: "<span>10</span> Receitas",
        active: true,
        platform: "both",
      },
      {
        description: "<span>10</span> Produtos de Venda",
        active: true,
        platform: "both",
      },
      {
        description: "Aplicativo",
        active: true,
        platform: "mobile",
      },
      {
        description: "Setup Financeiro",
        active: true,
        platform: "desktop",
      },
      {
        description: "Suporte por Chat",
        active: true,
        platform: "desktop",
      },
      {
        description: "Módulo de Compras",
        active: false,
        platform: "desktop",
      },
      {
        description: "Módulo de Eventos",
        active: false,
        platform: "desktop",
      },
      {
        description: "Rotulagem",
        active: false,
        platform: "desktop",
      },
      {
        description: "Multi-usuários",
        active: false,
        platform: "desktop",
      },
      {
        description: "Informação Nutricional",
        active: false,
        platform: "desktop",
      },
    ],
  },
  {
    label: "Essencial",
    recurrence: {
      monthly: {
        stripe_plan_id: "prod_FVa0BUJe0OBL2e",
        menu_plan_id: 25,
        rawPrice: 54.9,
        userPrice: "R$ 54.90",
      },
      yearly: {
        stripe_plan_id: "prod_JqbhRgpabkDVUL",
        menu_plan_id: 39,
        rawPrice: 459.9,
        userPrice: "R$ 459,90",
      },
    },
    features: [
      {
        description: "Matérias-primas <span>Ilimitadas</span>",
        active: true,
        platform: "both",
      },
      {
        description: "<span>100</span> Receitas",
        active: true,
        platform: "both",
      },
      {
        description: "<span>100</span> Produtos de Venda",
        active: true,
        platform: "both",
      },
      {
        description: "Aplicativo",
        active: true,
        platform: "mobile",
      },
      {
        description: "Setup Financeiro",
        active: true,
        platform: "desktop",
      },
      {
        description: "Suporte por Chat e Telefone",
        active: true,
        platform: "desktop",
      },
      {
        description: "Informação Nutricional",
        active: true,
        platform: "desktop",
      },
      {
        description: "Rotulagem",
        active: true,
        platform: "desktop",
      },
      {
        description: "Módulo de Compras",
        active: false,
        platform: "desktop",
      },
      {
        description: "Módulo de Eventos",
        active: false,
        platform: "desktop",
      },
      {
        description: "Multi-usuários",
        active: false,
        platform: "desktop",
      },
    ],
  },
  {
    label: "Pro",
    recurrence: {
      monthly: {
        stripe_plan_id: "prod_FVa92WQahG37nF",
        menu_plan_id: 32,
        rawPrice: 74.9,
        userPrice: "R$74,90",
      },
      yearly: {
        stripe_plan_id: "prod_JqbhRgpabkDVUL",
        menu_plan_id: 40,
        rawPrice: 629.9,
        userPrice: "R$629,90",
      },
    },
    features: [
      {
        description: "Matérias-primas <span>Ilimitadas",
        active: true,
        platform: "both",
      },
      {
        description: "Receitas <span>Ilimitadas</span>",
        active: true,
        platform: "both",
      },
      {
        description: "Produtos de Venda <span>Ilimitados</span> ",
        active: true,
        platform: "both",
      },
      {
        description: "Aplicativo",
        active: true,
        platform: "mobile",
      },
      {
        description: "Setup Financeiro",
        active: true,
        platform: "desktop",
      },
      {
        description: "Suporte por Chat e Telefone",
        active: true,
        platform: "desktop",
      },
      {
        description: "Informação Nutricional",
        active: true,
        platform: "desktop",
      },
      {
        description: "Rotulagem",
        active: true,
        platform: "desktop",
      },
      {
        description: "Módulo de Compras",
        active: true,
        platform: "desktop",
      },
      {
        description: "Módulo de Eventos",
        active: true,
        platform: "desktop",
      },
      {
        description: "Multi-usuários",
        active: true,
        platform: "desktop",
      },
    ],
  },
];

export default Plans;
