import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  position: relative;
  padding: 0 15px;
`;

export const Title = styled.h1`
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100%;
  font-family: Poppins;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  padding: 25px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  & div {
    display: flex;
    flex-direction: row;
  }
`;

export const PaymentDetails = styled.section`
  display: flex;
  flex-direction: column;
  & span {
    font-size: 20px;
    font-family: "Ubuntu";
  }
`;

export const CardDescription = styled.span<{ isTitle?: boolean }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: ${({ isTitle }) => (isTitle ? "bold" : "normal")};
  margin-left: ${({ isTitle }) => (isTitle ? 0 : 12)}px;
  font-size: 22px;
  color: #1f1f1f;
`;

export const CouponWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2px;
`;

export const CouponInput = styled.input`
  border-radius: 8px;
  border: 2px solid #d1d1d1;
  height: 65px;
  padding: 20px 15px;
  flex: 4;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-style: normal;
  margin-right: 20px;
  font-weight: normal;
  font-size: 15px;
  color: #1f1f1f;

  &:focus {
    outline: none;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #afafaf;
    opacity: 1; /* Firefox */
  }
`;
