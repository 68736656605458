import React, { useEffect, useRef, useState } from "react";
import { PlanLabel } from "../../@types/types";
import BottomNavigation from "../../components/BottomNavigation";
import PlanCard from "./components/PlanCard";
import Feature from "./components/Feature";
import Switch from "./components/Switch";

import { Container, Title, PlansCaroussel, FeaturesWrapper } from "./styles";
import Subtitles from "./components/Subtitles";
import { Plans as PlansData } from "../../data";
import useQuery from "../../hooks/useQuery";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const Plans = () => {
  const { query } = useQuery();
  const history = useHistory();
  const [selectedPlan, setSelectedPlan] = useState<PlanLabel>("Essencial");
  const [recurrence, setRecurrence] = useState<"monthly" | "yearly">("monthly");
  const carrouselRef = useRef<HTMLDivElement>(null);

  const animateCarrousel = () => {
    if (carrouselRef?.current) {
      setTimeout(() => {
        if (carrouselRef?.current) {
          carrouselRef.current.scrollLeft = carrouselRef?.current?.scrollWidth;
          setTimeout(() => {
            if (carrouselRef?.current) {
              carrouselRef.current.scrollLeft = 25;
            }
          }, 500);
        }
      }, 500);
    }
  };

  const authenticateUserAndCleanUrlParams = () => {
    if (query?.email && query?.token) {
      // Authenticate user
      api.defaults.headers.common = {
        "X-User-Email": query?.email,
        "X-User-Token": query?.token,
      };
      api.get("/account.json").catch((error) => {
        toast.error("Ocorreu um erro no login");
        history.push("/");
      });
      // And clean url params
      history.replace({
        search: "",
      });
    }
  };

  useEffect(() => animateCarrousel(), [carrouselRef?.current]);
  useEffect(() => authenticateUserAndCleanUrlParams(), [query]);

  return (
    <Container>
      <Title>PLANOS</Title>
      <Switch
        selectedOption={recurrence}
        onClick={(option) => setRecurrence(option)}
      />

      <PlansCaroussel ref={carrouselRef}>
        {PlansData.map((plan) => (
          <PlanCard
            {...plan}
            active={plan.label === selectedPlan}
            selectedRecurrence={recurrence}
            recommended={plan.label === "Essencial"}
            onClick={() => setSelectedPlan(plan.label)}
          />
        ))}
      </PlansCaroussel>
      <Subtitles />

      <FeaturesWrapper>
        {PlansData.find((plan) => plan.label === selectedPlan)?.features.map(
          (feature) => (
            <Feature {...feature} />
          )
        )}
      </FeaturesWrapper>
      <BottomNavigation
        onConfirm={() =>
          history.push({
            pathname: "/assinatura/checkout",
            search: `?plan=${selectedPlan}&recurrence=${recurrence}`,
          })
        }
      />
    </Container>
  );
};

export default Plans;
