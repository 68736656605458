import React from "react";

import { Container, Option, Wrapper, BadgePromo } from "./styles";

interface SwitchProps {
  selectedOption: "monthly" | "yearly";
  onClick: (option: "monthly" | "yearly") => void;
}

const Switch = ({ onClick, selectedOption }: SwitchProps) => {
  return (
    <Container>
      <Wrapper>
        <Option
          active={selectedOption === "monthly"}
          onClick={() => onClick("monthly")}
        >
          Mensal
        </Option>
        <Option
          active={selectedOption === "yearly"}
          onClick={() => onClick("yearly")}
        >
          Anual
          <BadgePromo>30% OFF</BadgePromo>
        </Option>
      </Wrapper>
    </Container>
  );
};

export default Switch;
