import styled from "styled-components";

export const Container = styled.div`
  margin-top: 30px;
`;

export const Input = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const InputStripeWrapper = styled.div`
  border: 2px solid #d1d1d1;
  background-color: #fff;
  border-radius: 8px;
  height: 65px;
  padding: 20px 15px;
`;

export const InputLabel = styled.h3``;

export const stripeInputStyle = {
  iconColor: "#aaa",
  color: "#000",
  fontWeight: "500",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  "::placeholder": {
    color: "#aaa",
  },
};
