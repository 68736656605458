import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
`;

export const Option = styled.h3<{ active?: boolean }>`
  display: flex;
  padding: 5px 15px;
  cursor: pointer;
  font-family: Poppins;
  position: relative;
  font-size: 20px;
  color: ${({ active }) => (active ? "#fff" : "#545454")};
  justify-content: center;
  background: ${({ active }) => (active ? "#E0552F" : "transparent")};
  border-radius: 30px;
`;

export const BadgePromo = styled.h3`
  display: flex;
  background-color: #4caf50;
  color: #fff;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  right: -10px;
  top: -12px;
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 15px;
  position: absolute;
`;
