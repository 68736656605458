import React from "react";
import { Plan } from "../../../../@types/types";

import { Container, Name, Price, Recurrence, BadgeRecommended } from "./styles";

interface PlanCardProps extends Plan {
  onClick?: () => void;
  active: boolean;
  recommended?: boolean;
  selectedRecurrence: "monthly" | "yearly";
}

const PlanCard = ({
  label,
  recurrence,
  recommended,
  selectedRecurrence,
  onClick,
  active,
}: PlanCardProps) => {
  return (
    <Container onClick={onClick} active={active}>
      {recommended && (
        <BadgeRecommended active={active}>RECOMENDADO</BadgeRecommended>
      )}
      <Name active={active}>{label}</Name>
      <Price active={active}>
        {recurrence?.[selectedRecurrence]?.userPrice || "R$ 0,00"}
      </Price>
      <Recurrence active={active}>
        {selectedRecurrence === "monthly" ? "/mês" : "/ano"}
      </Recurrence>
    </Container>
  );
};

export default PlanCard;
