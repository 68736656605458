import React from "react";
import { DesktopIcon, MobileIcon } from "../../../../assets/Icons";

import { Container, OptionWrapper, Label, Hr } from "./styles";

const Subtitles = () => {
  return (
    <Container>
      <OptionWrapper>
        <OptionWrapper style={{ justifyContent: "center", padding: 0 }}>
          <MobileIcon active />
          <Label style={{ marginLeft: 8 }}>Disponível no celular</Label>
        </OptionWrapper>

        <OptionWrapper style={{ justifyContent: "center", padding: 0 }}>
          <DesktopIcon active />
          <Label>Disponível no site</Label>
        </OptionWrapper>
      </OptionWrapper>
      <Hr />
    </Container>
  );
};

export default Subtitles;
