import React from "react";
import { Container } from "./styles";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  title: string;
  disabled?: boolean;
}

const Button = ({ title, disabled, ...props }: ButtonProps) => {
  return (
    <Container disabled={disabled} {...props}>
      {title}
    </Container>
  );
};

export default Button;
