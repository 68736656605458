import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import {
  Container,
  Input,
  InputLabel,
  InputStripeWrapper,
  stripeInputStyle,
} from "./styles";
import Button from "../../../components/Button";
import { parseStripeErrorMessage } from "../utils";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";

interface StripeCheckoutProps {
  couponId?: string;
  planId?: number;
  setLoading: (loading: boolean) => void;
}

const StripeCheckout = ({
  setLoading,
  couponId,
  planId,
}: StripeCheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handlePayment = () => {
    setIsButtonDisabled(true);
    setLoading(true);
    const card = elements?.getElement(CardNumberElement);
    if (card) {
      stripe
        ?.createToken(card)
        .then((result) => {
          if (result?.error) {
            // Inform the user if there was an error
            toast.error(parseStripeErrorMessage(result?.error));
            setIsButtonDisabled(false);
            setLoading(false);
          } else {
            let subscriptionParams: any = {
              plan_id: planId,
              card_token: result.token.id,
            };
            if (couponId) {
              subscriptionParams.coupon = couponId;
            }
            api
              .post(`plans/${planId}/subscription.json`, subscriptionParams)
              .then((result) => {
                setLoading(false);
                setIsButtonDisabled(false);
                history.push("/user-subscribed");
              })
              .catch((error) => {
                toast.error("Ocorreu um erro ao processar o pagamento.");
                setIsButtonDisabled(false);
                setLoading(false);
              });
          }
        })
        .catch((error) => {
          toast.error("Ocorreu um erro ao processar o pagamento.");
          setIsButtonDisabled(false);
          setLoading(false);
        });
    } else {
      setIsButtonDisabled(false);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Input>
        <InputLabel>Número do cartão</InputLabel>
        <InputStripeWrapper>
          <CardNumberElement
            options={{
              style: { base: stripeInputStyle },
            }}
          />
        </InputStripeWrapper>
      </Input>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Input style={{ marginRight: 10 }}>
          <InputLabel>Validade</InputLabel>

          <InputStripeWrapper>
            <CardExpiryElement
              options={{
                style: { base: stripeInputStyle },
              }}
            />
          </InputStripeWrapper>
        </Input>

        <Input style={{ marginLeft: 10 }}>
          <InputLabel>CVC</InputLabel>

          <InputStripeWrapper>
            <CardCvcElement
              options={{
                style: { base: stripeInputStyle },
              }}
            />
          </InputStripeWrapper>
        </Input>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Button
          style={{
            flex: 1,
            justifyContent: "center",
            padding: "20px 0",
            borderRadius: 5,
          }}
          disabled={isButtonDisabled}
          title="Assinar Plano"
          onClick={handlePayment}
        />
      </div>
    </Container>
  );
};

export default StripeCheckout;
