import useQuery from "./useQuery";

const urlPaths = ["/", "/login", "/user-subscribed"] as const;
type URLPath = typeof urlPaths[number];

interface ScreenProps {
  title: string;
  buttonTitle: string;
  redirectTo: string;
}

const useRedirectScreen = (): ScreenProps => {
  let { path = "/", query } = useQuery<URLPath>();

  if (!urlPaths.includes(path)) {
    path = "/";
  }

  const screens: {
    [key in URLPath]: ScreenProps;
  } = {
    "/login": {
      title: "Conclua o login clicando no botão abaixo.",
      buttonTitle: "Concluir Login",
      redirectTo: `menucontrol://auth/${query?.id_token}`,
    },
    "/user-subscribed": {
      title: "Conclua sua assinatura clicando no botão abaixo.",
      buttonTitle: "Concluir Assinatura",
      redirectTo: `menucontrol://user-subscribed`,
    },
    "/": {
      title: "Ocorreu um erro.",
      buttonTitle: "Voltar para o aplicativo",
      redirectTo: "menucontrol://",
    },
  };

  return screens[path];
};

export default useRedirectScreen;
