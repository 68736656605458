import styled from "styled-components";

export const Container = styled.button<{ disabled?: boolean }>`
  border: 0px;
  color: white;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  padding: 7px 15px;
  background: ${(props) => (props.disabled ? "#ccc" : "#e4531a")};
  font-family: Ubuntu;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
