import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  padding: 0 0px;
`;

export const Label = styled.h3`
  font-family: Ubuntu;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
`;

export const Hr = styled.div`
  width: 70%;
  height: 1.5px;
  margin: 10px 0;
  background-color: #f1f1f1;
`;
