import styled from "styled-components";

export const Container = styled.div<{ active?: boolean }>`
  position: relative;

  cursor: pointer;
  display: flex;
  background: ${({ active }) => (active ? "#E4531A" : "#ffffff")};
  border-radius: 15px;
  min-width: 170px;
  height: 150px;

  flex-direction: column;
  justify-content: center;
  padding: 30px 15px;
  margin: 0 10px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const Name = styled.h2<{ active?: boolean }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: ${({ active }) => (active ? "#f9f9f9" : "#545454")};
`;

export const Price = styled.h1<{ active?: boolean }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 27px;
  line-height: 35px;
  color: ${({ active }) => (active ? "#fff" : "#e4531a")};
`;

export const Recurrence = styled.h3<{ active?: boolean }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: ${({ active }) => (active ? "#f9f9f9" : "#545454")};
`;

export const BadgeRecommended = styled.h3<{ active?: boolean }>`
  display: flex;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  background-color: ${({ active }) => (active ? "#f9f9f9" : "#e0552f")};
  color: ${({ active }) => (active ? "#e0552f" : "#f9f9f9")};
  font-family: Poppins;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 15px;
`;
